import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const toastNormal = (message, type = 'info', position = 'bottom-center') => {
    toast( message, {
        type: type, // info, success, warning, error
        position: position
    })
}

export default {
    normal: toastNormal
}
