import { createRouter, createWebHistory } from 'vue-router';
import { VueCookieNext } from "vue-cookie-next";
import { useStore } from "vuex";
import $common from "@/plugins/common"

import defect from '@/routes/defect';
import home from '@/routes/home';
import sign from '@/routes/sign';

const routes = [
	{
		name: 'Splash',
		path: '/',
		meta: { layout: 'empty', pass: true },
		component: () => import ('@/views/splash/Splash.vue')
	},
	...defect,
	...home,
	...sign
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach( async (to, from, next) => {
	const store = useStore();
	const thisCookie = VueCookieNext.getCookie('loginSession')

	if(to.params.apartmentIdx)
	{
		if(store.state.chaedle.selected === null)
		{
			$common.setStoreSelected(to.params.apartmentIdx)
		}
	}

	if(to.meta.mapping && store.state.chaedle.dataset === null)
	{
		$common.setStoreDataset(to.params.apartmentIdx)
	}

	if(to.meta.pass)
	{
		if(thisCookie !== null)
		{
			store.commit('need_fetch')
			await store.dispatch('Session', thisCookie).then( (response) => {
				store.commit('need_fetch_done')
				if(response.status === 200)
				{
                    store.commit('setUser', response.data)
					VueCookieNext.setCookie('loginSession', response.data.session, {
						expire: '14d'
					})
					next();
					return;
				}
				setTimeout( () => {
					router.push({name: 'Login'})
				}, 500)
			})
			return;
		}
		setTimeout( () => {
			router.push({name: 'Login'})
		}, 500)
		return;
	}
	next();
})

export default router;
