<template>
	<v-app>
		<v-overlay
			class="align-center justify-center"
			persistent
			v-model="dimmer"
		>
			<v-progress-circular
				:size="50"
				color="primary"
				indeterminate
			/>
		</v-overlay>
		<v-main v-bind:id="$route.name" role="main">
      <div class="custom-container">
        <v-row class="ma-0 pa-0">
          <v-col cols="4" class="text-left"><v-icon icon="mdi-menu" size="large" /></v-col>
          <v-col cols="4" class="text-center d-flex justify-center align-center">
	          <v-img :src="chaedle_logo" alt="chaedle_logo" class="logo"/>
          </v-col>
          <v-col cols="4" class="text-right"><v-icon icon="mdi-home-outline" size="large" /></v-col>
        </v-row>
      </div>
			<slot />
		</v-main>
	</v-app>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { useStore } from 'vuex'
import chaedle_logo from "@/assets/images/chaedle_logo.svg"
import {useRoute, useRouter} from "vue-router";

const store = useStore();
const $router = useRouter();
const $route = useRoute();
const dimmer = ref(false);

const handleMoveHome = () => {
	$router.push({
		name: 'Home',
		params: {
			apartmentIdx: $route.params.apartmentIdx,
			householdIdx: $route.params.householdIdx,
			roomIdx: $route.params.roomIdx,
		}
	})
}

watch(
	() => store.state.chaedle.need_fetch,
	(n, o) => {
		dimmer.value = n;
	}
)

</script>
<style scoped lang="scss">
	.custom-container {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		background: #fff;
		margin-bottom: 13px !important;
		border-bottom: 1px solid #ccc;

		& .logo {
			max-width: 112px;
		}
	}
</style>
