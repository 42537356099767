export const chaedle = {
	state: () => ({
		need_fetch: false,
		need_refresh: false,
		area: null,
		construction: null,
		defect: null,
		dataset: null,
		household: null,
		mapping: null,
		selected: null,
		user: null
	}),
	mutations: {
		setUser(state, value) {
			state.user = value;
		},
		setConstruction(state, value) {
			state.construction = value;
		},
		setSelected(state, value) {
			state.selected = value;
		},
		setDataset(state, value) {
			state.dataset = value;
		},
		setMapping(state, value) {
			state.mapping = value;
		},
		setArea(state, value) {
			state.area = value;
		},
		need_fetch(state) {
			state.need_fetch = true;
		},
		need_fetch_done(state) {
			state.need_fetch = false;
		},
	},
	getters: {
		displayHousehold(state, getters, rootState) {
			return state.household;
		}
	}
}
