import AWS from 'aws-sdk';

const sts = new AWS.STS();
const AWS_STS_REGIONAL_ENDPOINTS = process.env.AWS_STS_REGIONAL_ENDPOINTS || 'legacy';
AWS.util.update(sts.constructor.prototype, {
	credentialsFrom: function(t, r) {
		return t ? (r || (r = new AWS.TemporaryCredentials),
			r.expired = !1,
			r.accessKeyId = t.Credentials.AccessKeyId,
			r.secretAccessKey = t.Credentials.SecretAccessKey,
			r.sessionToken = t.Credentials.SessionToken,
			r.expireTime = t.Credentials.Expiration,
			r) : null;
	},
	assumeRoleWithWebIdentity: function(t, r) {
		return this.makeUnauthenticatedRequest("assumeRoleWithWebIdentity", t, r);
	},
	assumeRoleWithSAML: function(t, r) {
		return this.makeUnauthenticatedRequest("assumeRoleWithSAML", t, r);
	},
	setupRequestListeners: function(t) {
		t.addListener("validate", this.optInRegionalEndpoint, !0);
	},
	optInRegionalEndpoint: function(t) {
		var r = t.service;
		var o = r.config;
		if (o.stsRegionalEndpoints = AWS.util.environment().AWS_STS_REGIONAL_ENDPOINTS || 'legacy',
		o.stsRegionalEndpoints === "regional" && r.isGlobalEndpoint) {
			if (!o.region)
				throw AWS.util.error(new Error(), {
					code: "ConfigError",
					message: "Missing region in config"
				});
			var a = o.endpoint.indexOf(".amazonaws.com"),
				i = o.endpoint.substring(0, a) + "." + o.region + o.endpoint.substring(a);
			t.httpRequest.updateEndpoint(i);
			t.httpRequest.region = o.region;
		}
	}
});

AWS.config.update({
	accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
	secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
	region: process.env.VUE_APP_AWS_REGION
});

const s3 = new AWS.S3();
const S3Plugin = {
	getObject: async (Bucket: string, Key: string) => {
		return new Promise((resolve, reject) => {
			s3.getObject({ Bucket, Key }, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data.Body);
				}
			});
		});
	},
	putObject: async (Bucket: string, Key: string, Body: Buffer | Uint8Array | Blob | string, ContentType: string) => {
		return new Promise((resolve, reject) => {
			s3.putObject({ Bucket, Key, Body, ContentType }, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	},
	listObject: async (Bucket: string, Prefix: string) => {
		return new Promise(( resolve, reject ) => {
			s3.listObjects({ Bucket, Prefix }, ( err, data ) => {
				if (err) {
					reject(err);
				}
				else {
					resolve(data);
				}
			});
		});
	}
}
export const thisPlugin = {
	s3: S3Plugin
}
