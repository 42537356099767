import {useStore} from "vuex";
import {thisPlugin as $aws} from "@/plugins/aws"
import videoThumbnail from "@/assets/images/logo.png"
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
moment.tz.setDefault('Asia/Seoul');

const common = {
    sprintf: (format, mixed) => {
        const regex = /%%|%(?:(\d+)\$)?((?:[-+#0 ]|'[\s\S])*)(\d+)?(?:\.(\d*))?([\s\S])/g;
        let i = 0;

        const _pad = (str, len, chr, leftJustify) => {
            if(!chr)
            {
                chr = ' ';
            }

            const padding = (str.length >= len) ? '' : new Array(1 + len - str.length >>> 0).join(chr);
            return leftJustify ? str + padding : padding + str;
        };

        const justify = (value, prefix, leftJustify, minWidth, padChar) => {
            const diff = minWidth - value.length;
            if(diff > 0)
            {
                if(!leftJustify && padChar === '0')
                {
                    value = [value.slice(0, prefix.length), _pad('', diff, '0', true), value.slice(prefix.length)].join('');
                }
                else
                {
                    value = _pad(value, minWidth, padChar, leftJustify);
                }
            }

            return value;
        };

        const _formatBaseX = (value, base, leftJustify, minWidth, precision, padChar) => {
            const number = value >>> 0;
            value = _pad(number.toString(base), precision || 0, '0', false);
            return justify(value, '', leftJustify, minWidth, padChar);
        };

        const _formatString = (value, leftJustify, minWidth, precision, customPadChar) => {
            if(precision !== null && precision !== undefined)
            {
                value = value.slice(0, precision);
            }

            return justify(value, '', leftJustify, minWidth, customPadChar);
        };

        const doFormat = (substring, argIndex, modifiers, minWidth, precision, specifier) => {
            let number, prefix, method, textTransform, value;

            if(substring === '%%')
            {
                return '%';
            }

            let padChar = ' ';
            let leftJustify = false;
            let positiveNumberPrefix = '';
            let j, l;

            for (j = 0, l = modifiers.length; j < l; j++)
            {
                switch(modifiers.charAt(j))
                {
                    case ' ' :
                    case '0' :
                        padChar = modifiers.charAt(j);
                        break;

                    case '+' :
                        positiveNumberPrefix = '+';
                        break;

                    case '-' :
                        leftJustify = true;
                        break;

                    case "'" :
                        if(j + 1 < l)
                        {
                            padChar = modifiers.charAt(j + 1);
                            j++;
                        }
                        break
                }
            }

            if(!minWidth)
            {
                minWidth = 0;
            }
            else
            {
                minWidth = +minWidth;
            }

            if(!isFinite(minWidth))
            {
                throw new Error('Width must be finite');
            }

            if(!precision)
            {
                precision = (specifier === 'd') ? 0 : 'fFeE'.indexOf(specifier) > -1 ? 6 : undefined;
            }
            else
            {
                precision = +precision;
            }

            if(argIndex && +argIndex === 0)
            {
                throw new Error('Argument number must be greater than zero');
            }

            if(argIndex && +argIndex >= mixed.length)
            {
                throw new Error('Too few arguments');
            }

            value = argIndex ? mixed[+argIndex] : mixed[i++];

            switch(specifier)
            {
                case '%' :
                    return '%';

                case 's' :
                    return _formatString(value + '', leftJustify, minWidth, precision, padChar);

                case 'c' :
                    return _formatString(String.fromCharCode(+value), leftJustify, minWidth, precision, padChar);

                case 'b' :
                    return _formatBaseX(value, 2, leftJustify, minWidth, precision, padChar);

                case 'o' :
                    return _formatBaseX(value, 8, leftJustify, minWidth, precision, padChar);

                case 'x' :
                    return _formatBaseX(value, 16, leftJustify, minWidth, precision, padChar);

                case 'X' :
                    return _formatBaseX(value, 16, leftJustify, minWidth, precision, padChar).toUpperCase();

                case 'u' :
                    return _formatBaseX(value, 10, leftJustify, minWidth, precision, padChar);

                case 'i' :
                case 'd' :
                    number = +value || 0;
                    number = Math.round(number - number % 1);
                    prefix = number < 0 ? '-' : positiveNumberPrefix;
                    value = prefix + _pad(String(Math.abs(number)), precision, '0', false);

                    if(leftJustify && padChar === '0')
                    {
                        padChar = ' ';
                    }

                    return justify(value, prefix, leftJustify, minWidth, padChar);

                case 'e' :
                case 'E' :
                case 'f' :
                case 'F' :
                case 'g' :
                case 'G' :
                    number = +value;
                    prefix = number < 0 ? '-' : positiveNumberPrefix;
                    method = ['toExponential', 'toFixed', 'toPrecision']['efg'.indexOf(specifier.toLowerCase())];
                    textTransform = ['toString', 'toUpperCase']['eEfFgG'.indexOf(specifier) % 2];
                    value = prefix + Math.abs(number)[method](precision);
                    return justify(value, prefix, leftJustify, minWidth, padChar)[textTransform]();

                default :
                    return '';
            }
        };

        try
        {
            return format.replace(regex, doFormat);
        }
        catch(e)
        {
            return false;
        }
    },
    setStoreSelected: async (apartmentIdx) => {
        const store = useStore();

        store.commit('setSelected', null);
        let key = null;
        store.commit('need_fetch');
        await $aws.s3.listObject(process.env.VUE_APP_S3_DEFAULT_BUCKET, common.sprintf("%s/apartment", [apartmentIdx]))
            .then( ( response ) => {
                key = response.Contents
                    .filter( v => v.Size > 0 && v.Key.split('.').pop().toLowerCase() === 'json' )
                    .sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))[0];
            })
            .catch((error) => {
                console.log( error );
            });
        await $aws.s3.getObject( process.env.VUE_APP_S3_DEFAULT_BUCKET, key?.Key )
            .then( (response) => {
                store.commit('setSelected', JSON.parse(response))
            } )
            .catch((error) => {
                console.log( error );
            });
        store.commit('need_fetch_done');
    },
    setStoreArea: async (apartmentIdx) => {
        const store = useStore();

        store.commit('setArea', null);
        let key = null;
        store.commit('need_fetch');
        await $aws.s3.listObject(process.env.VUE_APP_S3_DEFAULT_BUCKET, common.sprintf("%s/area", [apartmentIdx]))
            .then( ( response ) => {
                key = response.Contents
                    .filter( v => v.Size > 0 && v.Key.split('.').pop().toLowerCase() === 'json' )
                    .sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))[0];
            })
            .catch((error) => {
                console.log( error );
            });
        await $aws.s3.getObject( process.env.VUE_APP_S3_DEFAULT_BUCKET, key?.Key )
            .then( (response) => {
                store.commit('setArea', JSON.parse(response))
            } )
            .catch((error) => {
                console.log( error );
            });
        store.commit('need_fetch_done');
    },
    setStoreMapping: async (apartmentIdx) => {
        const store = useStore();

        store.commit('setMapping', null);
        let key = null;
        store.commit('need_fetch');
        await $aws.s3.listObject(process.env.VUE_APP_S3_DEFAULT_BUCKET, common.sprintf("%s/mapping", [apartmentIdx]))
            .then( ( response ) => {
                key = response.Contents
                    .filter( v => v.Size > 0 && v.Key.split('.').pop().toLowerCase() === 'json' )
                    .sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))[0];
            })
            .catch((error) => {
                console.log( error );
            });
        await $aws.s3.getObject( process.env.VUE_APP_S3_DEFAULT_BUCKET, key?.Key )
            .then( (response) => {
                store.commit('setMapping', JSON.parse(response))
            } )
            .catch((error) => {
                console.log( error );
            });
        store.commit('need_fetch_done');
    },
    setStoreDataset: async (apartmentIdx) => {
        const store = useStore();

        store.commit('setDataset', null);
        let key = null;
        store.commit('need_fetch');
        await $aws.s3.listObject(process.env.VUE_APP_S3_DEFAULT_BUCKET, common.sprintf(".dataset", [apartmentIdx]))
            .then( ( response ) => {
                key = response.Contents
                    .filter( v => v.Size > 0 && v.Key.split('.').pop().toLowerCase() === 'json' )
                    .sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))[0];
            })
            .catch((error) => {
                console.log( error );
            });
        await $aws.s3.getObject( process.env.VUE_APP_S3_DEFAULT_BUCKET, key?.Key )
            .then( (response) => {
                store.commit('setDataset', JSON.parse(response))
            } )
            .catch((error) => {
                console.log( error );
            });
        store.commit('need_fetch_done');
    },
    getApartmentThisEvent(obj) {
	    const thisEvent = obj.filter(v => v.event.activated);

	    if (thisEvent.length < 1) {
		    return null;
	    }

	    let current = thisEvent[0];
	    const now = new Date();

	    thisEvent.forEach(v => {
		    const eventStartDatetime = new Date(v.event.eventStartDatetime);

		    if (eventStartDatetime <= now) {
			    current = v;
		    }
	    });

	    return current;
    },
    getApartmentNextEvent(obj) {
        const today = new Date();
        let closestEvent = null;
        let closestDateDiff = Infinity;

        obj.forEach(eventObj => {
            if (eventObj.event.activated) {
                const eventStartDatetime = eventObj.event.eventStartDatetime ? new Date(eventObj.event.eventStartDatetime) : null;
                const inhabitantOpenStartDatetime = eventObj.event.inhabitantOpenStartDatetime ? new Date(eventObj.event.inhabitantOpenStartDatetime) : null;

                [eventStartDatetime, inhabitantOpenStartDatetime].forEach(date => {
                    if (date) {
                        const dateDiff = Math.abs(today - date);
                        if (dateDiff < closestDateDiff) {
                            closestDateDiff = dateDiff;
                            closestEvent = eventObj;
                        }
                    }
                });
            }
        });

        return closestEvent;
    },
    checkDateInRange(start, end, currentTime) {
		if(start && end)
		{
			const startDate = moment(start);
			const endDate = moment(end);

			return currentTime.isBetween(startDate, endDate, null, '[]');
		}

		return false;
    },
    thisRoom(roomIdx) {
        const store = useStore();
        return store.state.chaedle.dataset.space.find( v => v.d === true && v.i == roomIdx).c
    },
    thisLocation(location) {
        const locationArr = {
            CEILING: "천장",
            WALL: "벽",
            FLOOR: "바닥"
        }
        return locationArr[location];
    },
    thisStatus(status) {
        if(["WAITED"].includes(status))
        {
            return "대기";
        }
        if(['CONFIRMED', 'PROCESSING', 'PROCESSED', 'CHECKING', 'REPROCESSED', 'TRANSFERRED'].includes(status))
        {
            return "처리중";
        }
        if(['REEXAMINED'].includes(status))
        {
            return "비하자";
        }
        if(['CHECKED'].includes(status))
        {
            return "완료";
        }
        return status;
    },
    resizeImageToData(file, MAX_SIZE = 720)
    {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    let width = img.width;
                    let height = img.height;

                    if (width > MAX_SIZE || height > MAX_SIZE) {
                        if (width > height) {
                            height *= MAX_SIZE / width;
                            width = MAX_SIZE;
                        } else {
                            width *= MAX_SIZE / height;
                            height = MAX_SIZE;
                        }
                    }

                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    const resizedImage = canvas.toDataURL('image/png');
                    resolve({url: resizedImage, type: "image"});
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    },
    resizeImageToFile(file, MAX_SIZE = 720) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    let width = img.width;
                    let height = img.height;

                    if (width > MAX_SIZE || height > MAX_SIZE) {
                        if (width > height) {
                            height *= MAX_SIZE / width;
                            width = MAX_SIZE;
                        } else {
                            width *= MAX_SIZE / height;
                            height = MAX_SIZE;
                        }
                    }

                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    const thumbnail = canvas.toDataURL('image/png');

                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".png"), {
                            type: 'image/png',
                            lastModified: Date.now()
                        });
                        resolve({ data: resizedFile, url: thumbnail, type: "image", width: width, height: height });
                    }, 'image/png', 1);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    },
    compressVideo(file, limitSec = 15) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                const duration = video.duration;
                const width = video.videoWidth;
                const height = video.videoHeight;

                if (duration > limitSec) {
                    resolve(null);
                } else {
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(video, 0, 0, width, height);
                    const videoThumbnail = canvas.toDataURL('image/png');
                    resolve({ data: file, type: "video", url: videoThumbnail, width: width, height: height });
                }
            };

            video.onerror = (error) => reject(error);
        });
    },
    uuid() {
        return uuidv4();
    },
	decrypt_bidirectional: (text: string): any => {
		try
		{
			const config: any = {};
			config.iv = CryptoJS.enc.Utf8.parse('');
			config.padding = CryptoJS.pad.Pkcs7;
			config.mode = CryptoJS.mode.ECB;
			const result = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_BIDIRECTIONAL_KEY as string), config);
			return result.toString(CryptoJS.enc.Utf8);
		}
		catch(e)
		{
			return null;
		}
	}
}

export default common
