import { registerPlugins } from '@/plugins'
import App from './App.vue'
import { createApp } from 'vue'

import router from '@/routes';
import store from '@/store';
import axiosInstance from '@/plugins/axios';
import { VueCookieNext } from 'vue-cookie-next';
import * as Sentry from "@sentry/vue";

import { thisPlugin } from "@/plugins/aws";
import toast from "@/plugins/toast"
import moment from 'moment-timezone';
import common from "@/plugins/common"

import LayoutDefault from '@/layouts/Default'
import LayoutEmpty from '@/layouts/Empty'
import LayoutTitleback from '@/layouts/TitleBack.vue'

const app = createApp(App);

app.component('layout-default', LayoutDefault);
app.component('layout-empty', LayoutEmpty);
app.component('layout-titleback', LayoutTitleback);

app.config.globalProperties.$aws = thisPlugin;
app.config.globalProperties.$common = common;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$moment = moment;
moment.tz.setDefault('Asia/Seoul');

registerPlugins(app);

app.use(router);
app.use(store);
app.use(VueCookieNext);
app.config.globalProperties.$axios = axiosInstance

Sentry.init({
	app,
	dsn: "https://a9934eb5d7eed83ba5b268fa3949a1a1@o312484.ingest.us.sentry.io/4507525328666624",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

app.mount('#app');
