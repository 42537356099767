import $axios from "axios";
import qs from "qs";
import {VueCookieNext} from "vue-cookie-next";
import $common from "@/plugins/common"
const decryptData = (response) => {
	if(response.data?.name)
	{
		response.data.name = $common.decrypt_bidirectional(response.data.name);
	}

	if(response.data?.phone)
	{
		response.data.phone = $common.decrypt_bidirectional(response.data.phone);
	}

	if(response.data?.roomContact)
	{
		response.data.roomContact.contactRoomName = $common.decrypt_bidirectional(response.data.roomContact.contactRoomName);
		response.data.roomContact.contactRoomPhone = $common.decrypt_bidirectional(response.data.roomContact.contactRoomPhone);
	}

	if(response.data?.creatorExtraction)
	{
		response.data.creatorExtraction.name = $common.decrypt_bidirectional(response.data.creatorExtraction.name);
		response.data.creatorExtraction.phone = $common.decrypt_bidirectional(response.data.creatorExtraction.phone);
	}

	if(response.data?.memberExtraction)
	{
		response.data.memberExtraction.name = $common.decrypt_bidirectional(response.data.memberExtraction.name);
		response.data.memberExtraction.phone = $common.decrypt_bidirectional(response.data.memberExtraction.phone);
	}

	if(response.data?.items)
	{
		response.data.items = response.data.items.map((v: any) => {
			if(v.name)
			{
				v.name = $common.decrypt_bidirectional(v.name);
			}

			if(v.phone)
			{
				v.phone = $common.decrypt_bidirectional(v.phone);
			}

			if(v.roomContact)
			{
				v.roomContact.contactRoomName = $common.decrypt_bidirectional(v.roomContact.contactRoomName);
				v.roomContact.contactRoomPhone = $common.decrypt_bidirectional(v.roomContact.contactRoomPhone);
			}

			if(v.creatorExtraction)
			{
				v.creatorExtraction.name = $common.decrypt_bidirectional(v.creatorExtraction.name);
				v.creatorExtraction.phone = $common.decrypt_bidirectional(v.creatorExtraction.phone);
			}

			if(v.memberExtraction)
			{
				v.memberExtraction.name = $common.decrypt_bidirectional(v.memberExtraction.name);
				v.memberExtraction.phone = $common.decrypt_bidirectional(v.memberExtraction.phone);
			}

			return v;
		});
	}
	return response;
}

export const axios = {
	state: () => ({}),
	actions: {

		// 로그인
		async Login(commit, params, decrypt = false) {
			try {
				const response = await $axios.post(process.env.VUE_APP_API_BASE_URL + '/inhabitant/v1/session', {
					device: "WEB",
					username: params.username,
					password: params.password
				})
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},

		//유져정보
		async Session(commit, params, decrypt = true) {
			try {
				const response = await $axios.get(process.env.VUE_APP_API_BASE_URL + '/inhabitant/v1/session', {
					headers: {
						'Authorization': 'Bearer ' + params
					}
				})
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},

		//하자 리스트
		async defectList(commit, params, decrypt = false) {
			try {
				const thisCookie = VueCookieNext.getCookie('loginSession')
				const response = await $axios.get(process.env.VUE_APP_API_BASE_URL + `/inhabitant/v1/defect/${params.apartmentIdx}`, {
					headers: {
						'Authorization': 'Bearer ' + thisCookie
					},
					params: params,
					paramsSerializer: (params, decrypt = false) => {
						return qs.stringify(params, { arrayFormat: 'repeat' });
					}
				})
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},

		//하자 상세
		async defectDetail(commit, params, decrypt = false) {
			try {
				const thisCookie = VueCookieNext.getCookie('loginSession')
				const response = await $axios.get(process.env.VUE_APP_API_BASE_URL + `/inhabitant/v1/defect/${params.apartmentIdx}/${params.defectIdx}`, {
					headers: {
						'Authorization': 'Bearer ' + thisCookie
					},
					params: params
				})
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},

		//하자 등록
		async defectCreate(commit, params, decrypt = false) {
			try {
				const thisCookie = VueCookieNext.getCookie('loginSession')
				const response = await $axios.post(
					process.env.VUE_APP_API_BASE_URL + `/inhabitant/v1/defect/${params.apartmentIdx}`,
					params,
					{
						headers: {
							'Authorization': 'Bearer ' + thisCookie
						},
					}
				)
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},

		//세대 상세
		async roomDetail(commit, params, decrypt = false) {
			try {
				const thisCookie = VueCookieNext.getCookie('loginSession')
				const response = await $axios.get(process.env.VUE_APP_API_BASE_URL + `/inhabitant/v1/room/${params.apartmentIdx}/${params.roomIdx}`, {
					headers: {
						'Authorization': 'Bearer ' + thisCookie
					},
					params: params
				})
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},

		// OAuth 로그인
		async OAuth(commit, params, decrypt = false) {
			try {
				const response = await $axios.post(
					process.env.VUE_APP_API_BASE_URL + `/inhabitant/v1/oauth`,
					params
				)
				return decrypt ? decryptData(response) : response;
			}
			catch (e) {
				return { message: e.response.data.error };
			}
		},
	}
}
