<template>
	<v-app>
		<component v-bind:is="layout" v-bind:class="route_class">
			<router-view />
		</component>
	</v-app>
</template>

<script lang="ts">
export default
{
	computed:
	{
		layout()
		{
			return  'layout-' + (this.$route.meta.layout || 'empty');
		},
		route_class()
		{
			return 'route--' + this.$route.name;
		}
	}
}
</script>
<style scoped lang="scss">
html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden; /* Prevent horizontal scrolling */
}

#app {
	width: 100%;
	height: 100%;
	overflow-x: hidden; /* Prevent horizontal scrolling */
}
</style>
