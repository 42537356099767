export default [
	{
		name: 'defectList',
		path: '/defect-list/:apartmentIdx/:householdIdx/:roomIdx',
		meta: { layout: 'titleback', pass: true, name: "A/S 리스트", mapping: true },
		component: () => import ('@/views/defect/defectList.vue')
	},
	{
		name: 'defectDetail',
		path: '/defect/:apartmentIdx/:householdIdx/:roomIdx/:defectIdx',
		meta: { layout: 'titleback', pass: true, name: "A/S 상세", mapping: true },
		component: () => import ('@/views/defect/defectDetail.vue')
	},
	{
		name: 'defectCreate',
		path: '/defect-create/:apartmentIdx/:householdIdx/:roomIdx',
		meta: { layout: 'titleback', pass: true, name: "A/S 등록", mapping: true },
		component: () => import ('@/views/defect/defectCreate.vue')
	}
]
