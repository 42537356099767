export default [
	{
		name: 'Login',
		path: '/sign-in',
		meta: { layout: 'empty', pass: false },
		component: () => import ('@/views/sign/Login.vue')
	},
	{
		name: 'MyApartment',
		path: '/MyApartment',
		meta: { layout: 'empty', pass: true },
		component: () => import ('@/views/sign/MyApartment.vue')
	},
	{
		name: 'OAuth',
		path: '/oauth',
		meta: { layout: 'empty', pass: false },
		component: () => import ('@/views/sign/OAuth.vue')
	},
]
