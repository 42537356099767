<template>
  <v-app>
    <v-overlay
        class="align-center justify-center"
        v-model="dimmer"
    >
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      />
    </v-overlay>
    <v-main v-bind:id="$route.name" role="main">
      <slot />
    </v-main>
  </v-app>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { useStore } from 'vuex'

const store = useStore();

const dimmer = ref(false);

watch(
    () => store.state.chaedle.need_fetch,
    (n, o) => {
      dimmer.value = n;
    }
)

</script>
